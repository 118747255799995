
import AppMixin from '~/mixins/App'

export default {
  name: 'MenuIndex',
  mixins: [AppMixin],
  data() {
    return {
      isVi: false,
      isCollapse: false
    }
  },

  watch: {
    $route: {
      handler(val) {
        if (val.path === '/vi') {
          this.isVi = true
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    goTo(url) {
      if (!url) {
        return
      }

      window.open(url, '_blank')
    }
  }
}
